import React from 'react'
import Head from 'next/head'
import { NextPage, NextPageContext } from 'next'
import { handingServerError, isNotEmpty, getHost } from 'fbk-utils'

import * as api from 'api'
import Error from 'pages/_error'
import { Footer, Meta } from 'components'
import { SearchFormProvider, CaseProvider, SiteContentProvider } from 'contexts'
import { SearchFormValues, CaseData, SiteContent, NewsData } from 'types'

import Hero from './Hero'
import NewsList from './NewsList'
import SearchForm from './SearchForm'
import SearchResult from './SearchResult'

type QueryParams = {
  [key: string]: string
}

type PageProps =
  | {
      host: string
      queryParams: QueryParams
      caseData: CaseData | null
      siteContent: SiteContent
      newsData: NewsData
      error: null
    }
  | {
      error: {
        code: number
        message: string
      }
    }

const Home: NextPage<PageProps> = props => {
  if (props.error) {
    return <Error error={props.error} />
  }

  const { queryParams = {}, caseData, siteContent, newsData, host } = props
  const {
    recaptcha_site_key,
    heading_text,
    years,
    search_form_text,
    email_help,
  } = siteContent

  const initialValues: SearchFormValues = {
    person: queryParams.person || '',
    representative: queryParams.representative || '',
    number: queryParams.number || '',
    year: queryParams.year || '',
  }

  return (
    <>
      <SiteContentProvider data={siteContent} host={host}>
        <SearchFormProvider initialValues={initialValues}>
          <CaseProvider
            initSeacrhResult={isNotEmpty(queryParams)}
            caseData={caseData}
          >
            <Meta />
            <Head>
              <script
                src={`https://www.google.com/recaptcha/api.js?render=${recaptcha_site_key}`}
              />
            </Head>
            <Hero text={heading_text} />
            <SearchForm text={search_form_text} years={years} />
            <SearchResult />
            <NewsList data={newsData} />
            <Footer email={email_help} />
          </CaseProvider>
        </SearchFormProvider>
      </SiteContentProvider>
    </>
  )
}

export const getServerSideProps = async (
  ctx: NextPageContext,
): Promise<{ props: PageProps }> => {
  try {
    const { query } = ctx

    let caseData: CaseData | null = null

    const queryParams: any = {}

    Object.keys(query).forEach(param => {
      const necessaryParams = ['person', 'representative', 'number', 'year']

      if (necessaryParams.includes(param)) {
        queryParams[param] = query[param]
      }
    })

    const [siteContent, newsData] = await Promise.all([
      api.getSiteContent(),
      api.getNewsList({ page: 1 }),
    ])

    if (isNotEmpty(queryParams)) {
      caseData = await api.getCaseData({ params: queryParams })
    }

    return {
      props: {
        host: getHost(ctx),
        queryParams,
        siteContent,
        newsData,
        caseData,
        error: null,
      },
    }
  } catch (err) {
    const error = handingServerError(ctx, err)

    return {
      props: {
        error,
      },
    }
  }
}

export default Home
