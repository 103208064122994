import React from 'react'
import { NextApiResponse } from 'next'
import { ErrorProps } from 'next/error'
import { ErrorBlock } from 'components'
import * as Sentry from '@sentry/node'

interface Props {
  statusCode?: number
  error: any
}

const ErrorPage = ({ statusCode, error }: Props) => {
  if (error && statusCode !== 404) {
    const exception =
      error.error || error.message || error.originalError || error
    Sentry.captureException(exception)
  }
  return (
    <ErrorBlock
      statusCode={typeof error === 'object' ? error.code : statusCode || 500}
    />
  )
}

export const getServerSideProps = async ({
  res,
  err,
}: {
  res: NextApiResponse
  err?: ErrorProps
}) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 500

  if (err && statusCode !== 404) {
    Sentry.captureException(err)
    await Sentry.flush(2000)
  }

  return { props: { statusCode, error: err || null } }
}

export default ErrorPage
