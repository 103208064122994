import React, { useCallback, useContext, useState } from 'react'
import Router from 'next/router'
import styled, { css } from 'styled-components'
import { Section } from 'fbk-components'
import { getWordEnding, scrollToElement } from 'fbk-utils'
import { TitleWithBorder, Button, Case } from 'components'
import { CaseContext, SearchFormContext } from 'contexts'
import { CaseData } from 'types'
import { getCaseData } from 'api'
import NotFound from './NotFound'
import { hashes } from '../constants'

const Wrapper = styled(Section)`
  padding-top: 0;

  ${TitleWithBorder} {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    margin-bottom: 0;

    .title {
      margin-right: 12px;
      font-family: ${({ theme }) => theme.fonts.main};
      font-size: 20px;
      line-height: 24px;
    }

    .reset-btn {
      font-family: ${({ theme }) => theme.fonts.additional};
      font-size: 18px;
      text-decoration: underline;
      transition: 0.2s ease;

      :hover {
        opacity: 0.8;
      }
    }
  }

  ${Button} {
    margin-top: 40px;
  }

  @media (min-width: 768px) {
    .result-text {
      color: ${({ theme }) => theme.colors.grey};
    }

    ${TitleWithBorder} {
      padding-bottom: 24px;
    }

    ${Button} {
      width: 324px;
    }
  }
`

const ResultSpan = styled.span`
  ${({ count }: { count: number }) => css`
    display: ${count ? 'none' : 'block'};
  `}

  @media (min-width: 768px) {
    display: inline;
  }
`

const SearchResult = () => {
  const [isLoading, setLoading] = useState(false)

  const {
    initSeacrhResult,
    resetSearchResult,
    caseData: { count, results, next },
    setMoreData,
  } = useContext(CaseContext)

  const { resetForm } = useContext(SearchFormContext)

  const fetchMoreData = async () => {
    try {
      setLoading(true)
      const caseData: CaseData = await getCaseData({ url: next })
      setMoreData(caseData)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const handleReset = useCallback(() => {
    scrollToElement(`#${hashes.SEARCH_FORM}`, {
      top: 0,
      topOffset: 38,
    })

    resetForm()
    resetSearchResult()

    Router.push(
      {
        pathname: '/',
        query: {},
      },
      undefined,
      { shallow: true },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!initSeacrhResult) return null

  return (
    <Wrapper space="xl" id={hashes.SEARCH_RESULT}>
      <TitleWithBorder as="div">
        <p className="title">
          <ResultSpan count={count}>Результаты поиска</ResultSpan>
          {!!count && (
            <>
              <span className="g-hide-on-mobile">:</span>{' '}
              <span className="result-text">
                найдено {count} {getWordEnding(count, ['дело', 'дела', 'дел'])}
              </span>
            </>
          )}
        </p>
        <button className="reset-btn" onClick={handleReset}>
          Сбросить<span className="g-hide-on-mobile"> поиск</span>
        </button>
      </TitleWithBorder>
      {!!count ? (
        <>
          {results.map((caseItem, index) => (
            <Case key={index} isOnlyOne={count === 1} {...caseItem} />
          ))}
          {next && (
            <Button onClick={fetchMoreData} isGrey isLoader={isLoading}>
              Загрузить ещё
            </Button>
          )}
        </>
      ) : (
        <NotFound />
      )}
    </Wrapper>
  )
}

export default SearchResult
