import styled from 'styled-components'
import { Section } from 'fbk-components'
import { Text } from 'components'

export const Wrapper = styled(Section)`
  padding-top: 0;

  ${Text} {
    color: ${({ theme }) => theme.colors.grey};
  }
`

export const InputGroup = styled.div`
  > * {
    width: 100%;
  }

  > div {
    margin-top: 44px;
  }

  > button {
    margin-top: 32px;
  }

  .error-wrapper {
    display: flex;
    align-items: center;
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.primary};
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;

    > * {
      width: calc(50% - 20px);
    }

    .error-wrapper {
      display: flex;
      align-items: center;
      margin-top: 32px;
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`
