import React from 'react'
import styled from 'styled-components'
import { spacings } from 'theme'

const Wrapper = styled.div`
  padding-right: 32px;
  padding-left: 32px;
  text-align: center;
  background: ${({ theme }) => theme.colors.greyBg};
  ${spacings('padding-top', 'xl')}
  ${spacings('padding-bottom', 'xl')}
`

const NotFound = () => {
  return (
    <Wrapper>
      <h2>
        <span className="g-red-text">НИЧЕГО НЕ НАЙДЕНО :-(</span>
        <br />
        ПОПРОБУЙТе УТОЧНИТЬ ПАРАМЕТРЫ ПОИСКА
      </h2>
    </Wrapper>
  )
}

export default NotFound
