import React from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { Section } from 'fbk-components'
import { TitleWithBorder, NewsItem, Button } from 'components'
import { NewsData } from 'types'
import { NEWS_LIST } from 'constants/routes'
import { spacings } from 'theme'

interface NewsItem {
  date: string
  title: string
  text: string
}

const Wrapper = styled(Section)`
  ${Button} {
    ${spacings('margin-top', 'm')}
  }

  @media (min-width: 768px) {
    ${Button} {
      width: calc(50% - 20px);
    }

    .news-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
`

interface Props {
  data: NewsData
}

const NewsList = ({ data }: Props) => {
  const {
    items,
    meta: { total_count },
  } = data

  return (
    <Wrapper space="xl">
      <TitleWithBorder>Новости проекта</TitleWithBorder>
      <div className="news-list">
        {items.map((article, index) => (
          <NewsItem key={index} {...article} />
        ))}
      </div>
      {total_count > items.length && (
        <Link {...NEWS_LIST}>
          <Button color="primary" isLink>
            Смотреть все новости
          </Button>
        </Link>
      )}
    </Wrapper>
  )
}

export default NewsList
