import React from 'react'
import styled from 'styled-components'
import { Section } from 'fbk-components'
import { Text } from 'components'
import { Logo } from 'assets/svg'

const Header = styled(Section)`
  padding-bottom: 0;
  margin-bottom: 32px;

  h1 {
    display: none;
  }

  .logo {
    width: auto;
    max-width: 358px;
    height: auto;
  }

  ${Text} {
    margin-top: 32px;

    p:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  @media (min-width: 1200px) {
    ${Text} {
      column-count: 2;
      column-gap: 80px;
      padding-right: 40px;

      p:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }
`

interface Props {
  text: string
}

const Hero = ({ text }: Props) => {
  return (
    <Header as="header" space="l">
      <h1>ЕСПЧ поиск</h1> {/** for seo */}
      <Logo className="logo" />
      <Text as="div" dangerouslySetInnerHTML={{ __html: text }} />
    </Header>
  )
}

export default Hero
