import React, { useContext, useEffect, useState } from 'react'
import { Form, FormikProps } from 'formik'
import { SUGGEST_PERSON, SUGGEST_REPRESENTATIVE } from 'constants/endpoints'
import { SearchFormValues } from 'types'
import { SearchFormContext } from 'contexts'
import { Button, Input, Select, AsyncAutocomplete } from 'components'
import { InputGroup } from './styled'

interface Props {
  formikProps: FormikProps<SearchFormValues>
  years: number[]
  isLoading: boolean
  submitError: string | React.ReactNode
  setSubmitCount: any
}

const FormContent = ({
  formikProps,
  years,
  isLoading,
  submitError,
  setSubmitCount,
}: Props) => {
  const { resetForm, values, setFieldValue } = formikProps

  const { countReset } = useContext(SearchFormContext)

  const [countResetSelect, resetSelect] = useState(countReset)

  useEffect(() => {
    if (countReset) {
      resetForm()
      resetSelect(count => count + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countReset])

  useEffect(() => {
    if (values.number && values.year) {
      setFieldValue('year', '')
      resetSelect(count => count + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.number, values.year])

  const selectItems = [
    {
      label: 'Не выбрано',
      value: '',
    },
    ...years
      .sort((a, b) => b - a)
      .map(year => ({
        label: `20${year}`,
        value: `${year}`,
      })),
  ]

  return (
    <Form>
      <InputGroup>
        <AsyncAutocomplete
          name="person"
          label="Заявитель"
          url={SUGGEST_PERSON}
        />
        <AsyncAutocomplete
          name="representative"
          label="Адвокат"
          url={SUGGEST_REPRESENTATIVE}
        />
      </InputGroup>
      <InputGroup>
        <Input
          name="number"
          label="Номер дела"
          disableDecimals
          placeholder="Пример: 12345/20"
          type="number"
          maxLength={8}
          allowedСharacters={/\//}
        />
        <Select
          name="year"
          countReset={countResetSelect}
          disabled={!!values.number}
          placeholder="Выберите год"
          label="Год регистрации дела"
          items={selectItems}
        />
      </InputGroup>
      <InputGroup>
        <Button
          isLoader={isLoading}
          loaderText="Ищем..."
          type="submit"
          color="accent"
          onClick={() => setSubmitCount((value: number) => value + 1)}
        >
          Найти дело
        </Button>
        {submitError && (
          <div className="error-wrapper">
            <p className="error">{submitError}</p>
          </div>
        )}
      </InputGroup>
    </Form>
  )
}

export default FormContent
