import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import Router from 'next/router'
import { Formik } from 'formik'
import { scrollToElement } from 'fbk-utils'
import { Text } from 'components'
import { SearchFormContext, CaseContext } from 'contexts'
import { getCaseData } from 'api'
import { SearchFormValues, FormErrors, CaseData } from 'types'
import { parseApiErrors, filterObjectByValues } from 'utils/helpers'
import { Wrapper } from './styled'
import Form from './form'
import { hashes } from '../constants'

const StyledText = styled(Text)`
  @media (min-width: 1200px) {
    max-width: 560px;
  }
`

interface Props {
  text: string
  years: number[]
}

const SearchForm = ({ text, years }: Props) => {
  const [isLoading, setLoading] = useState(false)
  const [submitError, setError] = useState<string | React.ReactNode>('')
  const [submitCount, setSubmitCount] = useState(0)

  const { setCaseData } = useContext(CaseContext)
  const { initialValues } = useContext(SearchFormContext)

  const handleSubmit = async (values: SearchFormValues | any) => {
    try {
      setLoading(true)

      const query: any = filterObjectByValues({
        ...values,
      })

      const caseData: CaseData = await getCaseData({ params: query })

      Router.push(
        {
          pathname: '/',
          query,
        },
        undefined,
        { shallow: true },
      )

      setCaseData(caseData)
      scrollToElement(`#${hashes.SEARCH_RESULT}`, {
        top: 0,
        topOffset: 38,
      })
    } catch (err) {
      console.error(err)
      setError(parseApiErrors(err.error))
    } finally {
      setLoading(false)
    }
  }

  const handleValidate = ({
    number,
    person,
    representative,
    year,
  }: SearchFormValues) => {
    const noValues = number + person + representative + year === ''

    const errors: FormErrors = {}

    if (noValues) {
      errors.noValues = true
      setError(
        <>
          Задайте хотя бы один
          <br /> параметр поиска
        </>,
      )
    } else {
      delete errors.noValues
      setError('')
    }

    return errors
  }

  return (
    <Wrapper space="xl" id={hashes.SEARCH_FORM}>
      <StyledText as="div" dangerouslySetInnerHTML={{ __html: text }} />
      <Formik
        enableReinitialize
        validateOnBlur={false}
        onSubmit={handleSubmit}
        validate={handleValidate}
        initialValues={initialValues}
        validateOnChange={submitCount > 0}
      >
        {formikProps => (
          <Form
            formikProps={formikProps}
            years={years}
            isLoading={isLoading}
            submitError={submitError}
            setSubmitCount={setSubmitCount}
          />
        )}
      </Formik>
    </Wrapper>
  )
}

export default SearchForm
